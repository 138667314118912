/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {

        // All pages
        'common': {
            init: function() {
                // JavaScript to be fired on all pages





                // Navigation
                var navMenu     = $('.header__nav');
                var navOpen     = $('.nav_open');
                var navClose    = $('.nav_close');

                $('body').focusin(function(e) {
                    if (!$.contains($(navMenu)[0], document.activeElement)) {
                        navClose.trigger('click');
                    }
                });

                // keyboard navigation
                navMenu.on('keydown', function(event){
                    // keyCode -> http://www.javascripter.net/faq/keycodes.htm
                    // 27 = esc
                    if  (event.keyCode === 27) {
                        navClose.trigger('click');
                    }
                });

                // open navigation
                navOpen.click(
                    function(){
                        navOpen.attr('aria-expanded', navOpen.attr( 'aria-expanded' ) === 'false' ? 'true' : 'false' );
                        navClose.attr('aria-expanded', navClose.attr( 'aria-expanded' ) === 'false' ? 'true' : 'false' );
                        navMenu.addClass('open');
                    }
                );

                // close navigation
                navClose.click(
                    function(){
                        navOpen.attr('aria-expanded', navOpen.attr( 'aria-expanded' ) === 'false' ? 'true' : 'false' );
                        navClose.attr('aria-expanded', navClose.attr( 'aria-expanded' ) === 'false' ? 'true' : 'false' );
                        navMenu.removeClass('open');
                    }
                );

                // toggle sub-menu
                var menuItems = document.querySelectorAll('li.menu-item-has-children');
                Array.prototype.forEach.call(menuItems, function(el, i){
                	el.querySelector('a').addEventListener("click",  function(event){
                		if ( this.parentNode.classList.contains('open') ) {
                            this.parentNode.classList.remove('open');
                			this.setAttribute('aria-expanded', "false");
                		} else {
                			this.parentNode.classList.add('open');
                			this.setAttribute('aria-expanded', "true");
                		}
                		event.preventDefault();
                        //event.stopPropagation();
                		return false;
                	});
                });

                // Navigation after scroll
                var navAnchor = $('#main').first().offset().top + 200;
                var header = $('#header');

                $(window).on('scroll', function() {
                    var wst = $(window).scrollTop();
                    if( wst >= navAnchor ){
                        header.addClass('after-scroll');
                    } else {
                        header.removeClass('after-scroll');
                    }

                });





                // // AOS - animate on scroll
                // // setting
                // AOS.init({
                //     offset: 100,
                //     once: true,
                // });





                // Magnific Popup
                // Image
                var magPopImg = $('.magnificpopup');
                magPopImg.magnificPopup({
                    type: 'image',
                    image: {
                        titleSrc:
                        function(item) {
                            return item.el.find('img').attr('alt');
                        }
                    }
                });

                // Video
                var magPopVideo = $('.magnificpopup--video');
                magPopVideo.magnificPopup({
                    type: 'iframe',
                    iframe: {
                        markup:
                            '<div class="mfp-iframe-scaler">'+
                                '<div class="mfp-close"></div>'+
                                '<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>'+
                            '</div>', // HTML markup of popup, `mfp-close` will be replaced by the close button
                        patterns: {
                            youtube: {
                                index: 'youtube.com/', // String that detects type of video (in this case YouTube). Simply via url.indexOf(index).

                                id: 'v=', // String that splits URL in a two parts, second part should be %id%
                                // Or null - full URL will be returned
                                // Or a function that should return %id%, for example:
                                // id: function(url) { return 'parsed id'; }

                                src: '//www.youtube.com/embed/%id%' // URL that will be set as a source for iframe.
                            },
                        },
                    }
                });

                // Gallery
                var magPopGallery = $('.magnificpopup--gallery');
                magPopGallery.each(function() { // the containers for all your galleries

                    $(this).find('a').magnificPopup({
                        //delegate: 'a', // the selector for gallery item
                        type: 'image',
                        gallery: {
                            enabled: true
                        },
                        image: {
                            titleSrc:
                                function(item) {
                                    return item.el.find('img').attr('alt');
                                },
                        },
                    });

                });





            },
            finalize: function() {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },



        // // Home page
        // 'home': {
        //     init: function() {
        //         // JavaScript to be fired on the home page
        //     },
        //     finalize: function() {
        //         // JavaScript to be fired on the home page, after the init JS
        //     }
        // },


        // Page template Team, note the change from page-template-team to page_template_team.
        'page_template_team': {
            init: function() {
                // JavaScript to be fired


                // click to Avatar show corresponding Profile

                var teamAvatars = document.querySelectorAll('.avatars .avatar');
                var teamProfiles = document.querySelectorAll('.profiles .profile');

                Array.prototype.forEach.call(teamAvatars, function(el, i){

                	el.querySelector('a').addEventListener("click",  function(event){

                        var currentP = teamProfiles[i];

                        if ( currentP.classList.contains('show') ) {
                            currentP.classList.remove('show');
                            // return false;
                		} else {

                            for (var p = 0; p < teamProfiles.length; ++p) {
                                teamProfiles[p].classList.remove('show');
                            }
                			currentP.classList.add('show');
                		}
                        event.preventDefault();
                        return false;

                	});
                });

            },
            finalize: function() {
                // JavaScript to be fired, after the init JS
            }
        },





    };// Sage

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };// UTIL

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
